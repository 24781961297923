.social-container {
    padding: 25px 50px;
    font-size: 1vw;
}

a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}

a.youtube {
    color: #eb3223;
}

a.patreon {
    color: rgb(255, 66, 77);
}

/*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
@media screen and (min-width: 1024px){
    /*Style*/
    }
    
    /*###Tablet(medium)###*/
    @media screen and (min-width : 768px) and (max-width : 1023px){
    /*Style*/
      .social-container {
        font-size: 4vw;
      }
    }
    
    /*### Smartphones (portrait and landscape)(small)### */
    @media screen and (min-width : 0px) and (max-width : 767px){
    /*Style*/
      .social-container {
        font-size: 4vw;
      }
    }
    