.email-form {
    font-size: 2vw;
  }
    
  span {
    color: black
  }
  
  /*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
  @media screen and (min-width: 1024px){
  /*Style*/
  }
  
  /*###Tablet(medium)###*/
  @media screen and (min-width : 768px) and (max-width : 1023px){
  /*Style*/
    .email-form {
      font-size: 4vw;
    }
  }
  
  /*### Smartphones (portrait and landscape)(small)### */
  @media screen and (min-width : 0px) and (max-width : 767px){
  /*Style*/
    .email-form {
      font-size: 5vw;
    }
  }
  