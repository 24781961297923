.showThisWeek {
  text-align: center;
  font-family: 'WTR';
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-size: 12vw;
  width: 100%;
}

.extraText {
  font-size: 3vw;
}
  
span {
  color: black
}

/*###Desktops, big landscape tablets and laptops(Large, Extra large)####*/
@media screen and (min-width: 1024px){
/*Style*/
}

/*###Tablet(medium)###*/
@media screen and (min-width : 768px) and (max-width : 1023px){
/*Style*/
  .showThisWeek {
    font-size: 14vw;
  }
  .extraText {
    font-size: 4vw;
  }
}

/*### Smartphones (portrait and landscape)(small)### */
@media screen and (min-width : 0px) and (max-width : 767px){
/*Style*/
  .showThisWeek {
    font-size: 18vw;
  }

  .extraText {    
    display: flex;
    font-size: 6vw;
    padding-bottom: 10px;
  }

  .br-extraText {
    display: none;
  }
}
